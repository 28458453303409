<template>

<app-content>

	<app-content-head title="Scan" subtitle="Scan a QR code to update your progress in a contest." back="Convention.Schedule.Contests" />

	<app-content-body :no-padding="true" :is-call="is.checking || is.error || is.success" :loading="is.loading">

		<com-stream v-on:init="onInit" v-on:decode="onDecoded" v-show="!is.checking && !is.error && !is.success" />

		<app-content-call v-if="is.checking" icon="loading" title="Just a moment..." text="We're verifying the code you scanned." />

		<app-content-call v-if="is.error" :danger="true" :icon="errorIcon[is.error]" title="Sorry, couldn't scan code!" :text="errorText[is.error]" />

		<app-content-call v-if="is.success" :success="true" icon="tickOnly" title="Great, you're all set!" text="Your scan has been successful and your contest entry updated." />
	
	</app-content-body>

</app-content>

</template>

<script>

import {QrcodeStream} from 'vue-qrcode-reader'

export default {

	components: {
		'com-stream': QrcodeStream,
	},

	data: function() {

		return {
			is: {
				checking: false,
				loading: true,
				success: false,
				error: false
			},
			errorIcon: {
				1: 'crossOnly',
				2: 'crossOnly',
				3: 'crossOnly',
				4: 'crossOnly',
				5: 'crossOnly',
				6: 'crossOnly',
				7: 'crossOnly',
			},
			errorText: {
				1: 'This is an invalid QR code.',
				2: 'This is not an active contest.',
				3: 'You have already scanned this QR code.',
				4: 'This QR code has reached it\'s scan limit.',
				5: 'You must scan QR codes in sequence - this is a later one.',
				6: 'You did not register for this event before it began.',
				7: 'You recently scanned this QR code and must wait a while.'
			}
		}

	},

	created: function() {

		if (this.$route.query.code) {

			var code = this.$route.query.code

			/* this.$router.replace({
				name: 'Convention.Schedule.Contests.Scan'
			}) */

			this.checkCode(code)
			
		}

	},

	methods: {

		checkCode: function(code) {

			this.is.checking = true
			this.is.loading = false
			this.is.error = false
			this.is.success = false

			this.$api.post('convention/schedule/contests/scan', {
				code: code
			}).then(function() {

				this.is.checking = false
				this.is.success = true

			}.bind(this), function(json) {

				this.is.checking = false
				this.is.error = json.error

			}.bind(this))

		},

		onDecoded: function(value) {

			var code

			if (value.indexOf('https://') === 0) {

				var parts = value.split('/')

				code = parts.pop()

			}

			this.checkCode(code)

		},

		onInit: function(promise) {

			promise.then(function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>